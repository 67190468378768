'use strict';

const EventEmitter = require('events');

export default class BLESerial extends EventEmitter {

  async open() {
    try {
      return await this._open();
    } catch (error) {
      console.error(error);
      this.emit('close', this);
    }
  }

  async _open() {
    const self = this;

    this.log('Requesting any Bluetooth Device...');
    this._device = await navigator.bluetooth.requestDevice({
      // filters: [{services: ['6e400001-b5a3-f393-e0a9-e50e24dcca9e']}],
      acceptAllDevices: true,
      optionalServices: ['6e400001-b5a3-f393-e0a9-e50e24dcca9e'],
    });

    this._device.addEventListener('gattserverdisconnected', () => {
      self._device, self._tx, self._rx = undefined;
      self.log('GATT disconnected');
      self.emit('close', self);
    });

    this.log('Connecting to GATT Server...');
    const server = await this._device.gatt.connect();

    this.log('Getting Primary Service...');
    const service = await server.getPrimaryService('6e400001-b5a3-f393-e0a9-e50e24dcca9e');

    this.log('Getting Characteristics...');
    const characteristics = await service.getCharacteristics();

    for (let i = 0; i < characteristics.length; i++) {
      const characteristic = characteristics[i];

      this.log('Characteristic: ' + characteristic.uuid, characteristic);

      if (characteristic.uuid === '6e400002-b5a3-f393-e0a9-e50e24dcca9e') {
        this.log('is TX characteristic');
        this._tx = characteristic;
      }

      if (characteristic.uuid === '6e400003-b5a3-f393-e0a9-e50e24dcca9e') {
        this.log('is RX characteristic');
        this._line = '';
        this._rx = characteristic;
        this._rx.addEventListener('characteristicvaluechanged', this._onReceive.bind(self));
        this._rx.startNotifications();
      }
    }

    if (this._tx && this._rx) {
      this.emit('open', this);
      return true;
    }

    await this._device.gatt.disconnect();

    return false;
  }

  async close () {
    if (this._device) {
      await this._device.gatt.disconnect();
    }
  }

  async write(text) {
    const enc = new TextEncoder('ascii');
    const buffer = enc.encode(text);
    for (let i=0; i < buffer.length; i += 16) {
      try {
        await this._tx.writeValue(buffer.slice(i, i + 16));
      } catch (error) {
        console.error(error);
        this.close();
        return;
      }

      console.log(i);
    }
  }

  log(message) {
    this.emit('log', message);
    console.log(message);
  }

  _onReceive(event) {
    const decoder = new TextDecoder('ascii');
    const text = decoder.decode(event.target.value);

    for (let i = 0, l = text.length; i < l; i++) {
      if (text[i] == '\r') continue;
      if (text[i] == '\n') {

        //this.log('readline ' + this._line);
        this.emit('readline', this._line);
        this._line = '';

      } else {
        this._line += text[i];
      }
    }
  }

}
