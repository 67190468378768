import Vue from 'vue';
import Buefy from 'buefy';
import axios from 'axios';
import VueClipboard from 'vue-clipboard2';
import AsyncComputed from 'vue-async-computed';
import App from './App.vue';
import store from './store';
import ATClient from './lib/atclient';


import '@/css/main.css';
import 'buefy/dist/buefy.css';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Buefy);
Vue.use(AsyncComputed);
Vue.use(VueClipboard);
Vue.config.productionTip = false;
Vue.prototype.$http = axios;

const at = new ATClient();
at.on('state', (state) => {
  store.commit('at_state', state);
});
at.on('write', (line) => {
  store.commit('at_write', line);
});
at.on('read', (line) => {
  store.commit('at_read', line);
});
// at.on("urc", line => {
//     store.commit('at_urc', line);
// });

window.addEventListener('unload', ()=>{
  at.disconnect();
});

Vue.prototype.$at = at;

new Vue({
  store,
  render: (h) => h(App),
}).$mount('#app');

