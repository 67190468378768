<template>
  <div class="log" >

<table>
  <tr v-for="line in lines" v-bind:key="line.index">
    <td class="dir"> {{ line.ts }} </td>
    <td class="dir"> {{ line.dir }} </td>
    <td class="line"> <text-highlight :queries="find">{{ line.line }}</text-highlight> </td>
  </tr>

  </table>

  </div>
</template>

<script>
import TextHighlight from 'vue-text-highlight';

export default {
  name: 'Log',
  props: {
    autoScroll: Boolean,
    find: String,
    grep: String,
  },
  data() {
    return {
    };
  },
  components: {
    TextHighlight,
  },
  computed: {
    lines () {
      if (this.grep) {
        const grep = this.grep;
        return this.$store.state.lines.filter((line) => line.line.indexOf(grep) !== -1);
      }
      return this.$store.state.lines;
    },
  },
  updated() {
    if (!this.autoScroll) return;
    this.$el.scrollTop = Number.MAX_SAFE_INTEGER;
  },
  watch: {
    autoScroll (newValue) {
      if (newValue) {
        this.$el.scrollTop = Number.MAX_SAFE_INTEGER;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
