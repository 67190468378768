import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';

const ls = window.localStorage;

Vue.use(Vuex);

function formatDate(date) {
  var str = date.toISOString();
  return str.slice(0,10) + ' ' + str.slice(11,22);
}

export default new Vuex.Store({
  state: {
    conState: 'disconnected',
    lines: [],
    autoScroll: ls.getItem('autoScroll') !== 'false',
    ubxEnable: false,
    history: JSON.parse(localStorage.getItem('athistory') || '[]'),
  },
  mutations: {
    sync(state, payload) {
      state.conState = payload.state;
      state.lines = payload.lines;
    },
    at_state(state, conState) {
      state.conState = conState;
    },
    at_write(state, line) {
      state.lines.push({
        ts: formatDate(new Date()),
        line,
        dir: '>>',
      });

      if (line.length > 0) {
        state.history.unshift(line);
        if (state.history.length > 50) {
          state.history.pop();
        }
        localStorage.setItem('athistory', JSON.stringify(state.history));
      }
    },
    at_read(state, line) {
      if (line === 'AT$UBX') {
        state.ubxEnable = true;
      }
      state.lines.push({
        ts: formatDate(new Date()),
        line,
        dir: '<<',
      });
    },
    // at_utc(state, line) {
    // },
    socket_containers(state, containers) {
      state.containers = containers;
    },
    autoScroll(state, value) {
      state.autoScroll = value;
      ls.setItem('autoScroll', value);
    },
  },
  actions: {
  },
  plugins: [createLogger()],
});
