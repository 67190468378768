/* eslint-disable no-unused-vars */
<template>
  <div id="app">
    <nav class="navbar">
      <div class="navbar-brand">
        <a class="navbar-item" href="https://hardwario.com" target="_blank">
          <img src="./assets/Hw-logo-neg.svg" alt="HARDWARIO" />
        </a>
        <div class="navbar-item">Terminal</div>
        <span class="navbar-burger burger" data-target="navbarMenu">
          <span></span>
          <span></span>
          <span></span>
        </span>
      </div>
    </nav>

    <Log :autoScroll="autoScroll" :find="find" :grep="grep" />

    <footer class="footer">
      <form v-on:submit.prevent="submit">
        <div class="field has-addons">
          <div class="control is-expanded">
            <b-input
              v-model="onWrite"
              autofocus
              placeholder="Enter your command here"
              @keyup.native.up="onUp"
              @keyup.native.down="onDown"
            ></b-input>
          </div>
          <div class="control">
            <button class="button is-info">Send</button>
          </div>
        </div>
        <div class="is-hidden-mobile">
          <b-field horizontal label="Filter:" custom-class="is-small">
            <b-input v-model="grep" size="is-small"></b-input>
          </b-field>
          <b-field horizontal label="Highlight:" custom-class="is-small">
            <b-input v-model="find" size="is-small"></b-input>
          </b-field>
          <b-field horizontal label custom-class="is-small autoscroll" style="margin-top:4px;">
            <b-checkbox v-model="autoScroll" size="is-small">Auto Scroll &lt;F9&gt;</b-checkbox>
          </b-field>
          <b-field v-if="ubxEnable" horizontal label custom-class="is-small autoscroll" style>
            <button
              @click="isUbxModalActive = true"
              type="button"
              class="button is-info is-small"
            >UBX</button>
          </b-field>
        </div>
      </form>
    </footer>

    <b-modal :active="isModalActive" has-modal-card trap-focus aria-role="dialog" aria-modal>
      <b-loading v-if="conState === 'connection'" :is-full-page="true" :active="true" ></b-loading>
      <b-button disabled v-else-if="sessionId" @click="connect" type="is-primary" size="is-large">
        Wait on Device Connect
      </b-button>
      <div class="card" v-else-if="!isWebBLESupport">
        <div class="card-content">
          <b>You need to enable Bluetooth support in your Chrome browser.</b>
          <br />
          <br />Copy this link to url:
          <br />
          <b>chrome://flags/#enable-experimental-web-platform-features</b>
          <br />and set enable:
          <br />
          <b>Experimental Web Platform features</b>
        </div>
      </div>
      <div class="card" v-else-if="!isWebBLEAvailable">
        <div class="card-content">
          <b>Bluetooth radio is off.</b>
          <br />
          <b>Please turn it on to continue</b>
        </div>
      </div>
      <b-button v-else-if="conState === 'disconnected'" @click="connect" type="is-primary" size="is-large">
        Connect
      </b-button>
    </b-modal>

    <b-modal
      :active.sync="isUbxModalActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <div class="card">
        <div class="card-content" style="text-align: left;">
          <div class="field">
            <b-checkbox v-model="ubxForm.ini" >UBX-MGA-INI</b-checkbox>
          </div>
          <div class="field">
            <b-checkbox v-model="ubxForm.gps" >UBX-MGA-GPS</b-checkbox>
          </div>
          <div class="field">
            <b-checkbox v-model="ubxForm.gal" >UBX-MGA-GAL</b-checkbox>
          </div>
          <div class="field">
            <b-checkbox v-model="ubxForm.glo" >UBX-MGA-GLO</b-checkbox>
          </div>
          <div class="field">
            <b-checkbox v-model="ubxForm.bds" >UBX-MGA-BDS</b-checkbox>
          </div>
          <div class="field">
            <b-checkbox v-model="ubxForm.qzss" >UBX-MGA-QZSS</b-checkbox>
          </div>
          <div class="control">
            <button @click="writeUbx" class="button is-info">Send</button>
          </div>
        </div>
      </div>
    </b-modal>

    <div class="createSession" >
      <b-taglist v-if="sessionMyId" attached>
        <b-tag type="is-info">Session ID</b-tag>
        <span class="tag is-link" style="cursor: pointer;" v-on:click="copySessionID">
          <span class=""> {{sessionMyId}} </span>
        </span>
        <span class="tag is-danger" style="cursor: pointer;" v-on:click="sessionDestroy">
          <span class=""> Close </span>
        </span>
      </b-taglist>
      <b-taglist v-else-if="sessionId" attached>
        <b-tag type="is-info">Session ID</b-tag>
        <b-tag type="is-link">{{sessionId}}</b-tag>
        <span class="tag is-danger" style="cursor: pointer;" v-on:click="sessionLeave">
          <span class=""> Leave </span>
        </span>
      </b-taglist>
      <b-button v-else size="is-small" type="is-info" @click="sessionCreate">Create Session</b-button>
    </div>

  </div>
</template>

<script>
import Log from './components/Log.vue';
import io from 'socket.io-client';

export default {
  name: 'app',
  data() {
    return {
      find: '',
      grep: '',
      onWrite: '',
      onWriteBak: '',
      index: 0,
      isUbxModalActive: false,
      ubxForm: {
        ini: true,
        gps: true,
        gal: false,
        glo: false,
        bds: false,
        qzss: false,
      },
      sessionMyId: localStorage.getItem('sessionMyId'),
      sessionId: null,
    };
  },
  components: {
    Log,
  },
  created() {
    window.addEventListener('keydown', this.doCommand);

    if (!this.sessionMyId)
    {
      const q = location.search.match(/\?session=(\d+)/);

      if (q) {
        this.sessionId = q[1];
      }
    }

    this.sessionConnect();
  },
  destroyed() {
    window.removeEventListener('keydown', this.doCommand);
  },
  computed: {
    autoScroll: {
      get() {
        return this.$store.state.autoScroll;
      },
      set(value) {
        this.$store.commit('autoScroll', value);
      },
    },
    conState() {
      return this.$store.state.conState;
    },
    ubxEnable() {
      return this.$store.state.ubxEnable;
    },
    history() {
      return this.$store.state.history;
    },
    filteredAutocomplete() {
      return this.history.filter((option) => {
        return option.indexOf(this.onWrite) >= 0;
      });
    },
    isWebBLESupport() {
      return (
        navigator.bluetooth !== undefined &&
        navigator.bluetooth.requestDevice !== undefined
      );
    },
    isModalActive() {
      if (this.conState != 'connected') return true;
      if (this.sessionId) return false;
      return !this.isWebBLEAvailable;
    },
  },
  asyncComputed: {
    isWebBLEAvailable() {
      if (!this.isWebBLESupport) return false;
      return navigator.bluetooth.getAvailability();
    },
  },
  methods: {
    connect() {
      this.$at.connect();
    },
    submit() {
      if (this.sessionId) {
        this.socket.emit('write', this.onWrite);
      } else {
        this.$at.write(this.onWrite);
      }
      this.onWrite = '';
    },
    doCommand(e) {
      if (e.keyCode === 120) {
        e.preventDefault();
        this.autoScroll = !this.autoScroll;
      }
    },
    onUp() {
      if (this.index < this.history.length) {
        if (this.index++ == 0) {
          this.onWriteBak = this.onWrite;
        }
        this.onWrite = this.history[this.index - 1];
      }
    },
    onDown() {
      if (this.index > 0) {
        if (--this.index == 0) {
          this.onWrite = this.onWriteBak;
        } else {
          this.onWrite = this.history[this.index - 1];
        }
      }
    },
    async writeUbx() {
      console.log('writeUbx');
      this.isUbxModalActive = false;

      for (const key of ['ini', 'gps', 'gal', 'glo', 'bds', 'qzss']) {
        if (this.ubxForm[key]) {
          const { data } = await this.$http.get(`https://ubx.hardwario.cloud/v1/mga/${key}`);
          for (let i = 0; i < data.length; i += 1) {
            await this.$at.commandp(`$UBX="${data[i]}"`, 60000);
          }
        }
      }
    },
    sessionCreate() {
      this.sessionMyId = Math.floor(Math.random() * 1000000);
      localStorage.setItem('sessionMyId', this.sessionMyId);
      this.sessionConnect();
    },
    sessionDestroy() {
      localStorage.removeItem('sessionMyId');
      this.sessionMyId = null;
      this.sessionClose();
    },
    sessionLeave() {
      this.sessionClose();
      location.search='';
    },
    copySessionID() {
      const url = `${location.origin}?session=${this.sessionMyId}`;
      const snackbar = this.$buefy.snackbar;
      this.$copyText(url).then(() => {
        snackbar.open({
          message: `${url} Copied to clipboard`,
          position: 'is-top',
        });
      }, () => {
        snackbar.open({
          message: 'Can not copy',
          type: 'is-danger',
        });
      });
    },
    sessionConnect() {
      this.sessionClose();

      if (this.sessionMyId) {
        this.socket = io(`/${this.sessionMyId}`, {
          transports: ['websocket'],
        })
          .on('connect', () => {
            console.log('connect');
          })
          .on('write', (line) => {
            this.$at.write(line);
          })
          .on('newClinet', () => {
            this.socket.emit('sync', {
              state: this.$store.state.conState,
              lines: this.$store.state.lines,
            });
          });

        this.$at.on('state', (state) => {
          this.socket.emit('at', { event: 'state', data: state });
        });
        this.$at.on('write', (line) => {
          this.socket.emit('at', { event: 'write', data: line });
        });
        this.$at.on('read', (line) => {
          this.socket.emit('at', { event: 'read', data: line });
        });

      }
      else if (this.sessionId) {

        this.socket = io(`/${this.sessionId}`, {
          transports: ['websocket'],
        })
          .on('connect', () => {
            console.log('connect');
          })
          .on('sync', (payload) => {
            this.$store.commit('sync', payload);
          })
          .on('at', (payload) => {
            if (payload.event === 'state') {
              this.$store.commit('at_state', payload.data);
            }
            else if (payload.event === 'write') {
              this.$store.commit('at_write', payload.data);
            }
            else if (payload.event === 'read') {
              this.$store.commit('at_read', payload.data);
            }
          });
      }
    },
    sessionClose() {
      if (this.socket) {
        this.socket.off(); // remove an event
        this.socket.close();
        this.socket = null;
      }
    },
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

div.autoscroll {
  max-width: 0px !important;
  margin-right: 0px !important;
}

div.createSession {
  position: absolute;
  right: 5px;
  top: 10px;
  z-index: 50;
}
</style>
